.outer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #374550;
}

.inner {
  padding: 30px;
  border: 1px solid rgb(200, 30, 30);
  background: linear-gradient(
    rgb(230, 80, 80) 0%,
    rgb(255, 50, 50) 100%
  );
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  color: rgb(30, 30, 30);
  width: 600px;
  height: 300px;
}

h1 {
  font-family: 'Mandali';
  font-size: 5vw;
  margin: 0 20px;
}

h2 {
  font-family: 'Average Sans';
}